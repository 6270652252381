<template>
    <div class="feedback-page">
        <div class="title">
            反馈意见
        </div>
        <div class="search-div">
            <div style="display: flex;">
               <el-input size='medium' v-model="querySelect" style="margin-right: 10px;"></el-input>
               <el-button type="primary" size="small" @click="searchList">搜索</el-button>
            </div>
        </div>

        <div class="table-container">
            <el-table border :data="feedbackList">
                <el-table-column prop="openid" align="center" label="用户id" >
                </el-table-column>
                <el-table-column prop="phone" align="center" label="手机号">
                    <template slot-scope="scope">
                         <span>{{ scope.row.phone || '-' }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="wechat_id" align="center" label="微信号">
                    <template slot-scope="scope">
                         <span>{{ scope.row.wechat_id || '-' }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="content" align="center" label="内容">
                    <template slot-scope="scope">
                         <span>{{ scope.row.content || '-' }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="content" align="center" label="图片">
                    <template slot-scope="scope">
                         <img v-if="scope.row.image" @click="openDialog(scope.row.image)" :src="scope.row.image" style="width: 50px; height: 50px;" alt="">
                         <span v-else>-</span>
                    </template>
                </el-table-column>
                <el-table-column align="center" label="操作">
                    <template slot-scope="scope">
                         <span>
                             <el-popconfirm
                                confirmButtonText='确定'
                                cancelButtonText='不用了'
                                icon="el-icon-info"
                                iconColor="red"
                                @onConfirm="deleteFeedback(scope.row.feedback_id)"
                                title="确定删除该反馈意见吗？">
                                <el-button slot="reference" type="text" size="small">删除</el-button>
                            </el-popconfirm>
                         </span>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination class="page-pageination" background layout="prev, pager, next" :total="total" :page-size="size"
            :current-page.sync="page" @current-change="getFeedbackList">
            </el-pagination>
        </div>

        <el-dialog width="400px" :visible.sync="dialogVisible">
            <img width="100%" :src="dialogImageUrl" alt="">
        </el-dialog>
    </div>
</template>
<script>

export default {
    name: 'Feedback',
    data () {
        return {
            // 反馈意见列表
            feedbackList: [],
            page: 1,
            size: 10,
            total: 0,

            querySelect: '',
            dialogVisible: false,
            dialogImageUrl: ''
        }
    },
    mounted () {
        this.getFeedbackList()
    },
    methods: {
        // 获取反馈意见列表
        getFeedbackList () {
            this.axios.post('/feedback/list', {
                page: this.page,
                size: this.size
                // search: this.querySelect
            }).then((res) => {
                this.feedbackList = res.data.list
                this.total = res.data.count
                console.log(res)
            })
        },

        searchList () {
            this.page = 1
            this.getFeedbackList()
        },

        openDialog (image) {
            this.dialogVisible = true
            this.dialogImageUrl = image
        },

        // 删除
        deleteFeedback (ediFeedbackId) {
            console.log(ediFeedbackId)
            this.axios.post('/feedback/delete', {
                feedback_id: ediFeedbackId
            }).then((res) => {
                if (res) {
                    this.getFeedbackList()
                    this.$message.success('删除成功')
                }
            })
        }
    }
}
</script>

<style lang="scss">
    .feedback-page{
        .title{
            padding: 14px;
            // margin-bottom: 18px;
            border-bottom: 1px solid #dddddd;
            font-size: 28px;
        }

        .search-div{
            display: flex;
            justify-content: space-between;
            padding: 10px 20px;
            border-bottom: 1px solid #dddddd;
        }

        .table-container{
            margin: 10px 20px 0;
        }

        .page-pageination{
            padding: 10px 0;
            text-align: right;
        }
    }
</style>
